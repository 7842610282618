import React from 'react';
// import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby';
import SiteLayout from '@layouts/site-layout';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const our_partner = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 3,
    arrows: 'true',
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
         breakpoint: 480,
         settings: {
           slidesToShow: 1, 
         }
       },
   ]
  };


export default class InfoPage extends React.Component {
    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
            <SiteLayout title="media" description={description} image={img}>   
                <div className="top_margin_comn">
                    <div className="comn_bkgimg home_slider_sec" 
                    style={{background:"url('../img/abt_banner_slide.jpg') no-repeat center center /cover", color:"#fff"}}
                    > 
                        <div className="container">
                            <h3>Media</h3>
                            {/* <div className="brdcum">
                            <Link to="/">Index</Link> - Media
                            </div> */}
                        </div>
                    </div>
                </div> 

                <div className="container"> 
                    <Slider {...our_partner} className="row media_slide"> 
                    <div>
                         <div className="col-md-12">
                                <Link to="https://www.indiatoday.in/education-today/news/story/stepapp-ties-up-with-bihar-govt-to-support-girl-child-education-in-the-state-1764080-2021-01-29" className="media_items" target="_blank">
                                    <img src="img/news/bihar.jpg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">BIHAR EDUCATION</span>
                                        <p>STEPapp supports Girl Child Education in Bihar</p>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div>
                         <div className="col-md-12">
                                <Link to="https://livenewsgoa.com/2021/01/25/goa-education-stepapp-to-enhance-math-science-concepts-through-gamified-learning-tool/" className="media_items" target="_blank">
                                    <img src="img/news/goa.jpeg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">GOA EDUCATION</span>
                                        <p>Goa Education: ‘STEPapp’ to enhance math & science concepts through gamified learning tool</p>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div>
                         <div className="col-md-12">
                                <Link to="https://economictimes.indiatimes.com/tech/tech-bytes/stepapp-collaborates-with-uttarakhand-to-bring-new-age-gamification-technology/articleshow/80135641.cms" className="media_items" target="_blank">
                                    <img src="img/news/edtech.jpg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">UTTARAKHAND EDUCATION</span>
                                        <p>STEPapp Collaborates with Uttarakhand to bring new-age gamification technology</p>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div>
                            <div className="col-md-12">
                                <Link to="https://www.news18.com/news/movies/vijay-raaz-to-be-a-part-of-sanjay-leela-bhansalis-gangubai-kathiawadi-2409031.html" className="media_items" target="_blank">
                                    <img src="img/news/07.png" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">NEWS 18</span>
                                        <p>Vijay Raaz was speaking on the sidelines of the launch event of an educational app named STEPapp.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12">
                                <Link to="https://www.justdial.com/JdSocial/news/1575317844381000" className="media_items" target="_blank">
                                    <img src="img/news/19.jpg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">JUSTDIAL</span>
                                        <p>Educational Gaming app STEPapp Launched.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12">
                                <Link to="https://english.lokmat.com/entertainment/big-b-gives-app-launch-a-skip-sparks-off-fresh-health-scare-buzz/" className="media_items" target="_blank">
                                    <img src="img/news/06.jpg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">LOKMAT</span>
                                        <p>Big B gives app launch a skip, sparks off fresh health scare buzz.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12">
                                <Link to="https://www.apnnews.com/stepapp-to-revolutionize-k-12-education-in-india-with-gamification-of-learning/" className="media_items" target="_blank">
                                    <img src="img/news/01.jpg" alt="img-missing" />
                                    <div className="media_text">
                                        <span className="color_pink label_tag">APN NEWS</span>
                                        <p>Mumbai: Mr. Amitabh Bachchan gave a clarion call of ‘Apna Kal Khud Banao’.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Slider>
                </div>

        <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="media_left">
                     <img src="img/news/10.png" alt="img-missing" />
                     <Link to="https://www.thetimesofbengal.com/stepapps-collaboration-with-the-bihar-government-to-promote-girl-education/" className="media_text" target="_blank">
                        <span className="color_pink label_tag">STEPapp’s collaboration with Bihar</span> 
                    </Link>
                  </div>
                  <p className="media_para">Educating girls is one of the factors that brings growth prospects in the development of India. Every student, every child, irrespective of their gender, has the right and should be given the privilege to get quality education. STEPapp has always aimed to provide the best education to every child in every corner of the country.<a href="https://www.thetimesofbengal.com/stepapps-collaboration-with-the-bihar-government-to-promote-girl-education/"> Read More</a></p>
               </div> 

               <div className="col-md-6 text-center"> 
                    <a href="https://www.highereducationdigest.com/experts-reaction-on-indian-union-budget-2021-could-it-meet-the-expectation-of-education-sector/">
                        <img src="img/news/ptsir.jpg" width="100%" />
                        <p>Higher Education Digest coverage on Union Budget 2021: Mr Praveen Tyagi</p>
                    </a>
                </div> 
                
                <div className="col-md-6 text-center">
                    <img src="img/news/hindinews.jpg" />
                </div>  

                <div className="col-md-6 text-center">
                    <img src="img/news/gujnews.jpg" width="100%" />
                </div>
                
                <div className="col-md-6 mt-3">
                  <div className="media_left">
                     <img src="img/news/05.jpg" alt="img-missing" />
                     <Link to="https://in.news.yahoo.com/gamified-learning-tribal-public-private-085922160.html?guccounter=1" className="media_text" target="_blank">
                        <span className="color_pink label_tag">YAHOO NEWS </span>
                        <p>Gamified Learning for Tribal Public & Private School Students & Scholarships of Upto Rs. 50 Crore via STEPapp Created by 400 IITians</p>
                    </Link>
                  </div>
                  <p className="media_para">MUMBAI, Dec. 9, 2019 /PRNewswire/ -- Mr. Amitabh Bachchan gave a clarion call of 'Apna Kal Khud Banao' to students as India's first-of-its-kind Gamified Learning Ed-Tech app, STEPapp was unveiled by Eduisfun Technologies Pvt. Ltd. with an aim to revolutionize K-12 education in the country.<a href="https://in.news.yahoo.com/gamified-learning-tribal-public-private-085922160.html?guccounter=1"> Read More</a></p>
               </div>

                <div className="col-md-6 mt-3">
                  <Link to="https://maharashtratimes.com/gadget-news/science-technology/education-online-option-open-after-coronavirus-is-shutting-schools/articleshow/74761983.cms" className="media_right" target="_blank">
                  <div className="left_img">
                     <img src="img/news/01.jpg" alt="img_missing" />
                  </div>
                  <div className="right_text">
                  <span className="color_pink label_tag">MAHARASHTRA TIMES</span>
                  <p>विद्यार्थ्यांसाठी शिक्षणाचा ‘ऑन’लाइन पर्याय खुला</p>
                  </div>
                  </Link> 

                  <Link to="https://www.indiatoday.in/education-today/featurephilia/story/personalized-technology-platform-is-changing-k12-education-in-india-divd-1651523-2020-03-02" className="media_right" target="_blank">
                  <div className="left_img">
                     <img src="img/20.jpg" alt="img_missing" />
                  </div>
                  <div className="right_text">
                    <span className="color_pink label_tag">INDIA TODAY</span>
                    <p>Personalized technology platform is changing K12 education in India</p>
                  </div>
                  </Link> 

                  <Link to="https://indianexpress.com/article/technology/tech-news-technology/educational-gaming-app-stepapp-launched-6166207/" className="media_right">
                  <div className="left_img" target="_blank">
                     <img src="img/18.jpg" alt="img_missing" />
                  </div>
                  <div className="right_text">
                    <span className="color_pink label_tag">INDIAN EXPRESS</span>
                    <p>Educational gaming app STEPapp launched</p>
                  </div>
                  </Link> 

                  <Link to="https://www.thehindu.com/news/cities/mumbai/app-takes-education-through-games-to-tribal-schools-in-state/article30143383.ece" className="media_right" target="_blank">
                    <div className="left_img">
                        <img src="img/news/12.jpg" alt="img_missing" />
                    </div>
                    <div className="right_text">
                    <span className="color_pink label_tag">The Hindu</span>
                    <p>App takes education through games to tribal schools</p>
                    </div>
                  </Link>  
               </div>  
            </div>  
         </div> 

            </SiteLayout>
        )
    }
}
